import { WEBGL } from 'three/examples/jsm/WebGL.js';
import { Viewer } from './viewer.js';
import { Util } from './util.js';
import { SimpleDropzone } from 'simple-dropzone';
import { ValidationController } from './validation-controller.js';
import queryString from 'query-string';


if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
  console.error('The File APIs are not fully supported in this browser.');
} else if (!WEBGL.isWebGLAvailable()) {
  console.error('WebGL is not supported in this browser.');
}
//var SPACE_BASE_URL =  "https://fastoffice-glbdev.s3.us-east-2.amazonaws.com/projects";
//var SPACE_BASE_URL =  "https://fastoffice-glb.s3.us-east-2.amazonaws.com/projects";
var SPACE_BASE_URL =  "https://d1jm7d7hf3zow8.cloudfront.net";

console.info("fastoffice Space Viewer License Information https://viewer.fastoffice.com/license.txt");

class App {

    /**
     * @param  {Element} el
     * @param  {Location} location
     */
    constructor(el, location) {
   //     this.hideDropZone();
        const hash = location.hash ? queryString.parse(location.hash) : {};
        var opts = queryString.parse(location.search);

        if (!opts || !opts.mode) {
            opts = {
                'mode': 'prod'
            };
        }
        var url = location.href;
        if (hash.model && hash.model.indexOf('http') != -1) {
            //		console.log('hash is http url');
        } else {
            //		console.log('hash is not http url');
            var fileExtension = "glb";
            //hash.model=SPACE_BASE_URL+"/"+hash.model+"/glTF-Binary/"+hash.model+".glb";
            if (hash.model && hash.model != "") {
                hash.model = SPACE_BASE_URL + "/" + hash.model + "/" + fileExtension + "/" + hash.model + "." + fileExtension;
                //			console.log('hash model constructed = '+hash.model);
            }

        }
        this.options = {
            kiosk: Boolean(hash.kiosk),
            model: hash.model || '',
            preset: hash.preset || '',
            cameraPosition: hash.cameraPosition ?
                hash.cameraPosition.split(',').map(Number) :
                null,
            mode: opts.mode
        };


        this.el = el;
        this.viewer = null;
        this.viewerEl = null;
        this.spinnerEl = el.querySelector('.spinner');
        this.dropEl = el.querySelector('.dropzone');
        this.inputEl = el.querySelector('#file-input');

        this.validationCtrl = new ValidationController(el);


        this.hideSpinner();
  //      this.hideDropZone();

        const options = this.options;

        if (options.kiosk) {
            const headerEl = document.querySelector('header');
            headerEl.style.display = 'none';
        }

        if (options.model) {
            //		console.log('options model '+options.model);
			var topLogo = document.getElementById('fast-office-top-logo');
			if (topLogo) {
				topLogo.style.display = 'none';
			}
            this.showSpinner();
			try {
				this.view(options.model, '', new Map());
			} catch (e) {
				this.onError(e);
			}
        } else {
            this.createDropzone();
            this.showDropZone();
        }
    }

    /**
     * Sets up the drag-and-drop controller.
     */
    createDropzone() {
        const dropCtrl = new SimpleDropzone(this.dropEl, this.inputEl);
        dropCtrl.on('drop', ({
            files
        }) => this.load(files));
        dropCtrl.on('dropstart', () => this.showSpinner());
        dropCtrl.on('droperror', () => this.hideSpinner());
    }

    /**
     * Sets up the view manager.
     * @return {Viewer}
     */
    createViewer() {
        this.viewerEl = document.createElement('div');
        this.viewerEl.classList.add('viewer');
        this.dropEl.innerHTML = '';
        this.dropEl.appendChild(this.viewerEl);
        this.viewer = new Viewer(this.viewerEl, this.options);
        return this.viewer;
    }

    /**
     * Loads a fileset provided by user action.
     * @param  {Map<string, File>} fileMap
     */
    load(fileMap) {
        let rootFile;
        let rootPath;
        Array.from(fileMap).forEach(([path, file]) => {
            if (file.name.match(/\.(gltf|glb)$/)) {
                rootFile = file;
                rootPath = path.replace(file.name, '');
            }
        });

        if (!rootFile) {
            this.onError('No .gltf or .glb asset found.');
        }
		this.view(rootFile, rootPath, fileMap);
 
    }

    /**
     * Passes a model to the viewer, given file and resources.
     * @param  {File|string} rootFile
     * @param  {string} rootPath
     * @param  {Map<string, File>} fileMap
     */

    view(rootFile, rootPath, fileMap) {
        if (this.viewer) this.viewer.clear();

        const viewer = this.viewer || this.createViewer();

        const fileURL = typeof rootFile === 'string' ?
            rootFile :
            URL.createObjectURL(rootFile);

        const cleanup = () => {
            this.hideSpinner();
            progressUpdate("100");
            if (typeof rootFile === 'object') URL.revokeObjectURL(fileURL);
        };
	 
        viewer
            .load(fileURL, rootPath, fileMap)
            .catch((e) => this.onError(e))
            .then((gltf) => {
                if (this.options.mode == 'dev') {
                    this.validationCtrl.validate(fileURL, rootPath, fileMap, gltf);
                }
                cleanup();
            });
		 
    }

    /**
     * @param  {Error} error
     */
    onError(error) {
		
        let message = (error || {}).message || error.toString();
        if (message.match(/ProgressEvent/)) {
            message = 'Unable to retrieve this file. Check JS console and browser network tab.';
        } else if (message.match(/Unexpected token/)) {
            message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
        } else if (error && error.target && error.target instanceof Image) {
            message = 'Missing texture: ' + error.target.src.split('/').pop();
        } else {
			message = "Error in loading file";
		}
        // window.alert(message);
        console.error(error);
        this.hideSpinner();
        
    }

    showSpinner() {
      Util.showSpinner();
    }

    hideSpinner() {
		Util.hideSpinner();
		
	}
  
	showDropZone() {
		Util.showDropZone();
    }

    hideDropZone() {
      Util.hideDropZone();
    }
}


document.addEventListener('DOMContentLoaded', () => {

    const app = new App(document.body, location);

});

function progressUpdate(value) {
    Util.progressUpdate(value);
}