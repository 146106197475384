export class Util {
	
	static progressUpdate (value)  {
		var progressValueElement = document.querySelector('div[aria-valuenow]');
		if (progressValueElement) {
			progressValueElement.setAttribute('aria-valuenow',value);
			var progressPercent = "width: "+value+"%;"
			progressValueElement.setAttribute('style',progressPercent);
	//		console.log('progress value = '+value);
		}
	//	window.alert(progressValueElement);
	}
	
	static showSpinner() {
        //this.spinnerEl.style.display = '';
        var foProgressElement = document.getElementById('fospinner');
		var topLogo = document.getElementById('fast-office-top-logo');
		if (topLogo) {
			topLogo.style.display = 'none';
		}
        if (foProgressElement) {
            foProgressElement.style.display = 'block';
        }

    }

   static hideSpinner() {
        //this.spinnerEl.style.display = 'none';
        var foProgressElement = document.getElementById('fospinner');
		var topLogo = document.getElementById('fast-office-top-logo');
		if (topLogo) {
			topLogo.style.display = 'block';
		}
        if (foProgressElement) {
            //foProgressElement.style.visibility = 'none';
            foProgressElement.style.display = 'none';
        }
    }
	
	static showDropZone() {
        //this.spinnerEl.style.display = '';
        var dropzoneText = document.getElementById('dropzoneText');
        var uploadSection = document.getElementById('uploadSection');

        if (dropzoneText) {
            dropzoneText.style.visibility = 'block';
        }
        if (uploadSection) {
            upload.style.display = 'block';
        }

    }

    static hideDropZone() {
        //this.spinnerEl.style.display = 'none';
        var dropzoneText = document.getElementById('dropzoneText');
        var uploadSection = document.getElementById('uploadSection');

        if (dropzoneText) {
            dropzoneText.style.visibility = 'none';
        }
        if (uploadSection) {
            upload.style.display = 'none';
        }
    }

}